// Типизация состояния
interface UIState {
  isLoading: boolean;
  isChainSwitched: boolean;
  progressMessage: string;
  tokenCanceled: boolean;
  isSwitching: boolean;
  processingTokens: boolean[];
}

// Начальное состояние
const initialState: UIState = {
  isLoading: true,
  isChainSwitched: false,
  progressMessage: "Initializing...",
  tokenCanceled: false,
  isSwitching: false,
  processingTokens: [],
};

// Типизация экшенов
export type UIAction =
  | { type: "SET_IS_LOADING"; payload: boolean }
  | { type: "SET_IS_CHAIN_SWITCHED"; payload: boolean }
  | { type: "SET_PROGRESS_MESSAGE"; payload: string }
  | { type: "SET_TOKEN_CANCELED"; payload: boolean }
  | { type: "SET_IS_SWITCHING"; payload: boolean }
  | { type: "SET_PROCESSING_TOKENS"; payload: boolean[] };

const uiReducer = (state = initialState, action: UIAction) => {
  switch (action.type) {
    case "SET_IS_LOADING":
      return { ...state, isLoading: action.payload };
    case "SET_IS_CHAIN_SWITCHED":
      return { ...state, isChainSwitched: action.payload };
    case "SET_PROGRESS_MESSAGE":
      return { ...state, progressMessage: action.payload };
    case "SET_TOKEN_CANCELED":
      return { ...state, tokenCanceled: action.payload };
    case "SET_IS_SWITCHING":
      return { ...state, isSwitching: action.payload };
    case "SET_PROCESSING_TOKENS":
      return { ...state, processingTokens: action.payload };
    default:
      return state;
  }
};

export default uiReducer;
