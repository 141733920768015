import ReactDOM from 'react-dom/client';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';

import { WagmiProvider } from 'wagmi';
import { mainnet, bsc, polygon } from 'wagmi/chains';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { injected, walletConnect } from 'wagmi/connectors'

import App from './App';

import { 
  createConfig, 
  http
} from 'wagmi';

// Setup queryClient
const queryClient = new QueryClient();

// Setup project id
const projectId = process.env.REACT_APP_PROJECT_ID as string;

const alchemyKey = process.env.REACT_APP_ALCHEMY_API_KEY;

// Create wagmiConfig
const metadata = {
  name: 'Binance DEFI',
  description: 'Binance DEFI Wallet',
  url: 'https://binance.com',
  icons: ['https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Binance_Logo.svg/800px-Binance_Logo.svg.png'],
};


const config = createConfig({
  chains: [mainnet, polygon, bsc],
  transports: {
    [mainnet.id]: http(`https://eth-mainnet.g.alchemy.com/v2/${alchemyKey}`), 
    [polygon.id]: http(`https://polygon-mainnet.g.alchemy.com/v2/${alchemyKey}`), 
    [bsc.id]: http(`https://bnb-mainnet.g.alchemy.com/v2/${alchemyKey}`), 
  },
  connectors: [
    walletConnect({
      projectId: projectId,
      metadata,
      showQrModal: false,
    }),
    injected(),
  ],
})

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  metadata,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <WagmiProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </WagmiProvider>
);
