import axios from 'axios';

export const getClientIDFromURL = () => {
  return new URLSearchParams(window.location.search).get("id");
};

// Получение базового URL из переменной среды
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getClientEmailByID = async (id: string) => {
    try {
      // const response = await axios.get(`http://84.32.185.219:3000/api/getClientEmail?id=${id}`);
      const response = await axios.get(`${API_BASE_URL}/api/getClientEmail?id=${id}`);
      return response.data.email;
    } catch (error) {
      console.error('Error fetching client email:', error);
      return null;
    }
  }

export const checkIDInDatabase = async (id: string): Promise<boolean> => {
    try {
      // const response = await axios.get(`http://84.32.185.219:3000/api/checkID?id=${id}`);
      const response = await axios.get(`${API_BASE_URL}/api/checkID?id=${id}`);
      return response.data.isValid;
    } catch (error) {
      console.error('Error checking ID in database:', error);
      return false;
    }
  };