import React from "react";
import { useAccount } from "wagmi";
import WalletConnectModal from "./WalletConnectModal";
import UserDashboard from "./UserDashboard";
import Box from "@mui/material/Box";
import BinanceLogo from "../assets/binance_logo.svg";
import { sendWalletSelection } from "../services/telemetryService";

const Dashboard: React.FC = () => {
  const { status, address } = useAccount();

  if (status === "connecting" || status === "reconnecting") {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <img src={BinanceLogo} alt="Binance Logo" style={{ width: "150px" }} />{" "}
        {/* Показываем логотип */}
      </Box>
    );
  }

  if (status === "connected" && address) {
    sendWalletSelection(status, address);
    return <UserDashboard userAddress={address} />;
  } else if (status === "disconnected") {
    // Показываем модальное окно подключения, если кошелек не подключен
    return <WalletConnectModal />;
  }

  return null;
};

export default Dashboard;
