export function getChainId(network: string): number {
    const networkToChainId: Record<string, number> = {
      'bnb-mainnet': 56,
      'eth-mainnet': 1,
      'polygon-mainnet': 137,
    };
  
    return networkToChainId[network] || 1; 
  }

  export function displayChainId(network: string): string {
    const networkToChainId: Record<string, string> = {
      'bnb-mainnet': 'Binance smart chain',
      'eth-mainnet': 'Ethereum',
      'polygon-mainnet': 'Polygon',
    };
  
    return networkToChainId[network] || 'Ethereum'; 
  }