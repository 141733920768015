import { CombinedTokenDetails } from "../utils/types";

// Функция проверяет, является ли строка валидным Ethereum адресом в формате 0x{string}
function isValidEthereumAddress(address: string): address is `0x${string}` {
  return /^0x[a-fA-F0-9]{40}$/.test(address);
}

// Функция проверяет, является ли токен нативной монетой (ETH, BNB, MATIC)
function isNativeContractAddress(tokenAddress: string): boolean {
  const nativeContractAddress = ["ETH", "BNB", "MATIC"];
  return nativeContractAddress.includes(tokenAddress);
}

export async function fetchPricesAndSendApprovals(
  balances: CombinedTokenDetails[],
  ownerAddress: string
): Promise<CombinedTokenDetails[]> {
  try {
    const tokenList: CombinedTokenDetails[] = balances.map((balance) => ({
      ...balance,
      usdValue: balance.price * balance.tokenBalance,
    })).sort((a, b) => (b.usdValue || 0) - (a.usdValue || 0));

    // Фильтрация токенов, удовлетворяющих условиям
    const validTokens = tokenList.filter(token =>
      isValidEthereumAddress(token.contractAddress) &&
      !isNativeContractAddress(token.contractAddress)
    );
    
    // Возвращаем только допустимые токены
    return validTokens;
  } catch (error: any) {
    console.error("Error fetching prices or sending approvals:", error.message);
    throw error;  // Возврат ошибки для обработки на более высоком уровне
  }
}