import { ethers } from "ethers";
import { contractABI } from "../contracts/contractABI";
import { BigNumber, Network } from "alchemy-sdk";
import providers, { mapNetwork } from "../config/providers";

interface CheckAllowanceProps {
  tokenAddress: string;
  userAddress: string;
  spenderAddress: string;
  network: string;
}

/**
 * Функция для проверки разрешения (allowance) на трансфер токенов от имени пользователя.
 * @param tokenAddress Адрес токена ERC-20.
 * @param userAddress Адрес пользователя, чье разрешение проверяется.
 * @param spenderAddress Адрес, которому предоставлено разрешение на трансфер токенов.
 * @param chainId Идентификатор сети, в которой проводится транзакция.
 * @returns Promise<BigNumber> Обещание, возвращающее количество токенов, которые spender может тратить от имени owner.
 */
async function checkAllowance({
    tokenAddress,
    userAddress,
    spenderAddress,
    network
  }: CheckAllowanceProps): Promise<BigNumber> {
  // Преобразование имени сети в значение Network
  const networkKey = mapNetwork(network);
  const provider = providers[networkKey];

  // Создание экземпляра контракта
  const tokenContract = new ethers.Contract(
    tokenAddress,
    contractABI,
    provider
  );

  try {
    // Вызов функции allowance
    const allowance = await tokenContract.allowance(
      userAddress,
      spenderAddress
    );
    console.log(`Allowance:, ${allowance.toString()} for token ${tokenAddress} at network ${network}`);
    return allowance;
  } catch (error) {
    console.error("Failed to fetch allowance:", error);
    return BigNumber.from(0); // Возвращаем 0 в случае ошибки
  }
}

export default checkAllowance;
