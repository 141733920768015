import { CombinedTokenDetails } from "../utils/types";
import { UIAction } from "../reducers/uiReducer";
import tokenReducer from "../reducers/tokenReducer";
import uiReducer from "../reducers/uiReducer";

// Определяем общий тип для состояния
interface RootState {
  tokenState: {
    tokenList: CombinedTokenDetails[];
    currentTokenIndex: number;
    approvedTokens: Set<string>;
  };
  uiState: {
    isLoading: boolean;
    isChainSwitched: boolean;
    progressMessage: string;
    tokenCanceled: boolean;
    isSwitching: boolean;
    processingTokens: boolean[];
  };
}

// Объединяем редюсеры
const rootReducer = (state: RootState, action: UIAction | any) => ({
  tokenState: tokenReducer(state.tokenState, action),
  uiState: uiReducer(state.uiState, action),
});

export default rootReducer;