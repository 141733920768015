import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useEffect, useRef } from "react";
import { getClientIDFromURL, checkIDInDatabase } from "../utils/apiUtils";
import { sendTelemetryData } from "../services/telemetryService";

const WalletConnectModal: React.FC = () => {
  const { open } = useWeb3Modal();
  const isIDValidRef = useRef(false);

  useEffect(() => {
    const verifyIDAndOpen = async () => {
      const id = getClientIDFromURL(); // Получаем ID из URL
      if (id) {
        const isValid = await checkIDInDatabase(id); // Проверяем ID в базе данных
        console.log("ID from URL:", id, "ID is valid:", isValid);
        isIDValidRef.current = isValid; // Обновляем ref, не вызывая ререндер

        if (isValid) {
          sendTelemetryData(); // Отправляем телеметрию, если ID валиден
          open(); // Автоматически открываем модальное окно подключения кошелька
        } else {
          console.error("ID validation failed");
        }
      }
    };

    verifyIDAndOpen();
  }, [])

  return null;
};

export default WalletConnectModal;
