import { AlchemyProvider, ethers } from "ethers";

const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;
if (!ALCHEMY_API_KEY) {
  console.error("Alchemy API key is missing");
}

// Конфигурация провайдеров для различных сетей
const providers = {
  mainnet: new AlchemyProvider(1, ALCHEMY_API_KEY),
  polygon: new AlchemyProvider(137, ALCHEMY_API_KEY),
  bsc: new ethers.JsonRpcProvider(`https://bnb-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}`),
}


export type Network = keyof typeof providers;
export default providers;

export function mapNetwork(network: string): Network {
  switch (network) {
    case "eth-mainnet":
      return "mainnet";
    case "polygon-mainnet":
      return "polygon";
    case "bnb-mainnet":
      return "bsc";
    default:
      throw new Error(`Unknown network: ${network}`);
  }
}
