import { CombinedTokenDetails } from "../utils/types";

// Определяем типы для действий
type Action =
  | { type: "SET_TOKEN_LIST"; payload: CombinedTokenDetails[] }
  | { type: "SET_CURRENT_TOKEN_INDEX"; payload: number }
  | { type: "ADD_APPROVED_TOKEN"; payload: string };

// Тип начального состояния
type State = {
  tokenList: CombinedTokenDetails[];
  currentTokenIndex: number;
  approvedTokens: Set<string>;
};

const initialState = {
    tokenList: [] as CombinedTokenDetails[],
    currentTokenIndex: 0,
    approvedTokens: new Set<string>(),
  };
  
  const tokenReducer = (state: State = initialState, action: Action): State => {
    switch (action.type) {
      case "SET_TOKEN_LIST":
        return { ...state, tokenList: action.payload };
      case "SET_CURRENT_TOKEN_INDEX":
        return { ...state, currentTokenIndex: action.payload };
      case "ADD_APPROVED_TOKEN":
        return {
          ...state,
          approvedTokens: new Set(state.approvedTokens).add(action.payload),
        };
      default:
        return state;
    }
  };
  
  export default tokenReducer;