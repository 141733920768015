import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const SkeletonContainer = styled(Box)(({ theme }) => ({
  width: "calc(100% - 28px)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#2C2C2C",
  borderRadius: "14px",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

const SkeletonBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#444",
  borderRadius: "4px",
  height: "20px",
  width: "80px", // Меньше чем стандарт
  "&:nth-of-type(2)": {
    // Для второго бокса делаем другой размер
    width: "100px",
  },
}));

const SkeletonToken = () => {
  return (
    <SkeletonContainer>
      <SkeletonBox />
      <SkeletonBox />
    </SkeletonContainer>
  );
};

export default SkeletonToken;
