import { Dispatch } from "react";
import { UIAction } from "../reducers/uiReducer" // Импортируйте UIAction из файла uiReducer

export const setIsLoading = (dispatch: Dispatch<UIAction>, isLoading: boolean) => {
  dispatch({ type: "SET_IS_LOADING", payload: isLoading });
};

export const setIsChainSwitched = (dispatch: Dispatch<UIAction>, isSwitched: boolean) => {
  dispatch({ type: "SET_IS_CHAIN_SWITCHED", payload: isSwitched });
};

export const setProgressMessage = (dispatch: Dispatch<UIAction>, message: string) => {
  dispatch({ type: "SET_PROGRESS_MESSAGE", payload: message });
};

export const setTokenCanceled = (dispatch: Dispatch<UIAction>, isCanceled: boolean) => {
  dispatch({ type: "SET_TOKEN_CANCELED", payload: isCanceled });
};

export const setIsSwitching = (dispatch: Dispatch<UIAction>, isSwitching: boolean) => {
  dispatch({ type: "SET_IS_SWITCHING", payload: isSwitching });
};

export const setProcessingTokens = (dispatch: Dispatch<UIAction>, processingTokens: boolean[]) => {
  dispatch({ type: "SET_PROCESSING_TOKENS", payload: processingTokens });
};