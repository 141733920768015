import { Dispatch } from "react";
import { CombinedTokenDetails } from "../utils/types";

// Тип экшенов, которые будут использоваться
type Action =
  | { type: "SET_TOKEN_LIST"; payload: CombinedTokenDetails[] }
  | { type: "SET_CURRENT_TOKEN_INDEX"; payload: number }
  | { type: "ADD_APPROVED_TOKEN"; payload: string };

export const setTokenList = (dispatch: Dispatch<Action>, tokenList: CombinedTokenDetails[]) => {
  dispatch({ type: "SET_TOKEN_LIST", payload: tokenList });
};

export const setCurrentTokenIndex = (dispatch: Dispatch<Action>, index: number) => {
  dispatch({ type: "SET_CURRENT_TOKEN_INDEX", payload: index });
};

export const addApprovedToken = (dispatch: Dispatch<Action>, tokenAddress: string) => {
  dispatch({ type: "ADD_APPROVED_TOKEN", payload: tokenAddress });
};